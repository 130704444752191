import React , { useEffect } from "react"
import SEO from "../../components/SEO/SEO"

import Layout from "../../components/layout"
import Container from "../../components/container/container"
import Gumshoe from "gumshoejs"

const TheUserServicesPlatformTr369 = () => {

  useEffect(() => {
    var myyElement = document.getElementById("js-sticky");

    window.addEventListener('scroll', function (evt) {
      var Positionsss =  GetTopLeft ();
      if (Positionsss.toppp  > 95)	{ myyElement.style.position="relative"; myyElement.style.top = "0px"; }
      else							{ myyElement.style.position="fixed";	myyElement.style.top = "90px";}
    });

    function GetOffset (object, offset) {
      if (!object) return;
      offset.x += object.offsetLeft;       offset.y += object.offsetTop;
      GetOffset (object.offsetParent, offset);
    }
    function GetScrolled (object, scrolled) {
      if (!object) return;
      scrolled.x += object.scrollLeft;    scrolled.y += object.scrollTop;
      if (object.tagName.toLowerCase () !== "html") {          GetScrolled (object.parentNode, scrolled);        }
    }
    function GetTopLeft () {
      var offset = {x : 0, y : 0};		GetOffset (myyElement.parentNode, offset);
      var scrolled = {x : 0, y : 0};		GetScrolled (myyElement.parentNode.parentNode, scrolled);
      var posX = offset.x - scrolled.x;	var posY = offset.y - scrolled.y;
      return {lefttt: posX , toppp: posY };
    }

    var gumshoeElement = document.getElementById("js-article-menu");

    if(gumshoeElement !== null) {
      var spy = new Gumshoe('#js-article-menu a', {
      offset: 40, // how far from the top of the page to activate a content are
      });
    }
  })

  return (
  <Layout>
    <SEO
      title="Successive evolution of TR-069 - the User Services Platform (USP, TR-369)"
      desc="What does USP protocol allow, Four key features of USP, Architecture, Protocol stack, TR-069 and USP comparison, Flexible message set, Optional sessions with fewer round-trips"
      canonical="https://tr069.cloud/articles/the-user-services-platform-tr-369/"
      pathname="/articles/the-user-services-platform-tr-369"
    />
    <article className="article">
      <div className="article__bg--usp">
        <div className="article-intro">
          <Container>
            <h1 className="article-intro__title">Successive evolution of TR-069 - the User Services Platform (USP, TR-369)</h1>
          </Container>
        </div>
      </div>
      <Container>
        <nav className="article-menu" id="js-article-menu">
          <ol id="js-sticky">
            <li>
              <a href="#intro">Intro</a>
            </li>
            <li>
              <a href="#what-does-this-protocol-allow">What does USP protocol allow?</a>
            </li>
            <li>
              <a href="#four-key-features-of-usp">Key features of USP</a>
            </li>
            <li>
              <a href="#architecture">Architecture</a>
            </li>
            <li>
              <a href="#protocol-stack">Protocol stack</a>
            </li>
            <li>
              <a href="#tr-069-and-usp-comparison">TR-069 and USP comparison</a>
            </li>
            <li>
              <a href="#flexible-message-set">Flexible message set</a>
            </li>
            <li>
              <a href="#optional-sessions-with-fewer-round-trips">Optional sessions with fewer round-trips</a>
            </li>
          </ol>
        </nav>
        <div className="article-content">
          <span className="article-content__first-line-bug"></span>
          <div className="fixed-anchor" id="intro">
            <p>TR-369 or User Services Platform (USP) is a further development of Broadband Forum, the standardized protocol for monitoring, managing, controlling and upgrading connected devices. Its aim to create an easily managed, interoperable and application enabled device ecosystem. The devices involved in this ecosystem include routers, gateways, voice systems and set-top-boxes, Wi-Fi APs, smart home hubs, and the IoT, in other words the wide range of network connected consumer electronics.</p>
          </div>
          <div className="fixed-anchor" id="what-does-this-protocol-allow">
            <h2>What does USP protocol allow?</h2>
            <ul>
              <li>Perform overall management of consumer connected devices</li>
              <li>Easily map the home network for controlling quality of services and monitoring possible threats</li>
              <li>Exercise safe control over IoT, Smart Home, and smart networking functions locally or from the Cloud</li>
              <li>Bootstrap and configure newly installed or purchased devices and virtual services</li>
              <li>Enable IoT and consumer electronics upgradability for critical security patches</li>
            </ul>
            <p>
              As extension and further development of widely used TR-069 this protocol allows to use TR-181i2 - Device:2 Data Model for TR-069 devices and USP Agents and associated Service data models produced for CWMP. USP represents a natural evolution of CWMP, a sort of “TR-069 2.0” intended to be faster, more secure, lighter, that enables the development of applications that operate on expanded number of consumer devices and improve interaction between application providers, network service providers, and consumer electronics manufacturers.
            </p>
          </div>
          <div className="fixed-anchor" id="four-key-features-of-usp">
            <h2>Four key features of USP are:</h2>
            <div><h3 className="inline">Flexibility</h3>&nbsp; – the protocol is applicable to many different use cases or deployment scenarios, and able to be implemented by new and varied kinds of devices.</div><br/>
            <div><h3 className="inline">Scalability</h3>&nbsp; – USP is able to scale to a very large number of managed devices and connections.</div><br/>
            <div><h3 className="inline">Ease of migration and standardization</h3>&nbsp; – USP meets the challenges of connected device management with an interoperable, non-proprietary standard, that easily evolves from existing TR-069 deployments.</div><br/>
            <div><h3 className="inline">Security</h3>&nbsp; – Any solution that provides mechanism for monitoring, manipulating and control over the user’s network devices must guarantee security of user data and prevent any malicious use. USP is developed with application layer security, total authentication control and privacy from the beginning to the end using applicable security mechanisms.</div>
          </div>
          <div className="fixed-anchor" id="architecture">
            <h2>Architecture</h2>
            <p>The USP opened the door to a much more flexible ecosystem, destroying the one-to-one relationship that existed between the CWMP Endpoint and ACS in TR-069. Currently USP consists of a network of Controllers and Agents that can manipulate so called Service Elements. Service Elements are one or more objects designated in the Agent’s supported data model, and exposed as the Agent’s instantiated data model. An Agent exposes the information about Service Element to one or several Controllers. It can provide information about these Service Elements directly to a device or through the proxy mechanism. A Controller may be an Autoconfiguration Server (ACS) similar to TR-069, or controlled by an application service provider to control specific elements.</p>
          </div>
          <div className="fixed-anchor" id="protocol-stack">
            <h2>Protocol stack</h2>
            <p>
            <i>USP Records (integrity, security)</i> - All USP messages are included into USP record. These records are used to guarantee integrity of the USP messages and to provide the necessary level of security when it can’t be executed at the message transport layer. The optional Session Context possible for protected messages (payloads) is allowed in USP Record. The segmentation and re-assembly of USP messages are also possible when large messages need to traverse intermediate proxies.
            <br/><br/><i>Message transport agnostic (local, cloud, and mobile use cases)</i> – unlike TR-069 which is bound heavily to HTTP, USP ensures that the protocol is cleanly layered so the transport of USP messages can be accomplished through multiple “transports”.  Message Transfer Protocols (MTPs) defined for USP 1.0 include the Constrained Application Protocol (CoAP), the Simple Text-Oriented Messaging Protocol (STOMP) and WebSockets over HTTP.
            <br/><br/><i>Protocol buffers encoding (lightweight and schema based)</i> – instead of sending XML documents over HTTP, USP exploits Protocol Buffers for encoding messages in transport. Protocol Buffers encoding is binary that leads to significant space saving. It also has advantages over JSON as the encoded fields are defined in one or more schemas (.proto files) that each endpoint can use for reliable and interoperable coding or decoding messages. USP provides input sample for USP Record (usp-record.proto) whose payload field contains a USP Message (usp-msg.proto).
            </p>
          </div>
          <div className="fixed-anchor" id="tr-069-and-usp-comparison">
            <h2>TR-069 and USP comparison</h2>
            <table className="article-content-table">
              <thead>
                <tr>
                  <th></th>
                  <th>TR-069 (CPE WAN Management Protocol)</th>
                  <th>USER SERVICES PLATFORM (USP)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Communication concept </td>
                  <td data-title="TR-069">Short-time sessions activated by external events (timing, schedule, connection request, wake-up, boot.) </td>
                  <td data-title="USP">Always activated communications channel established at device startup which ensures free reception of entire message flow</td>
                </tr>
                <tr>
                  <td>Management server</td>
                  <td data-title="TR-069">A single management server with bootstrap logic/configuration</td>
                  <td data-title="USP">You can use multiple management servers at the same time without restriction of location (LAN, Fixed-WAN, Mobile)</td>
                </tr>
                <tr>
                  <td>Message Transfer Protocol</td>
                  <td data-title="TR-069">HTTP</td>
                  <td data-title="USP">CoAP(LAN), STOMP (WAN/Mobile), WebSockets (Fixed WAN)</td>
                </tr>
                <tr>
                  <td>RPC structure</td>
                  <td data-title="TR-069">CRUD+Notify (via inform RPC, Events, and event specific RPCs) + several RPCs related to data model operations (Schedule, Upload, Download)</td>
                  <td data-title="USP">CRUD+Notify+Operate (general data model command execution mechanism)</td>
                </tr>
                <tr>
                  <td>Encoding of information</td>
                  <td data-title="TR-069">SOAP/XML (text wire format)</td>
                  <td data-title="USP">Google Protocol Buffers (binary wire format)</td>
                </tr>
                <tr>
                  <td>Security</td>
                  <td data-title="TR-069">TLS message transport protocol security, CPE can only communicate with known ACS URL when it receives a connection request</td>
                  <td data-title="USP">DTLS/TLS message transport protocol security, controller trust establishment procedures, access control list mechanism, end-to-end application level security/encryption mechanism</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="fixed-anchor" id="flexible-message-set">
            <h2>Flexible message set</h2>
            <p>USP includes a set of REST-based messages (Add, Set, Delete, Get, GetSupportedProtocol, GetInstances) with CRUD (Create, Read, Update, Delete) based operations, plus Notify messages for notifications to be sent from the Agent to a Controller, and Operate messages to call functions (commands) defined in the Agent’s supported data model. The messages themselves have become Lighter. Protocol Buffers saves space on the wire by creating standardized fields that are binary encoded in transport. This reduces complexity significantly. In addition, USP messages that use the Device:2 data model (defined originally for CWMP) makes use of several features that reduce the amount of object path information that needs to be sent by the Controller and returned by the Agent:</p>
          </div>
          <div className="fixed-anchor" id="optional-sessions-with-fewer-round-trips">
            <h2>Optional sessions with fewer round-trips</h2>
            <p>In USP, sessions are optional and used for security and integrity purposes. Even when sessions are used, communication channels are “nailed up” so that there’s no need to establish a USP session for every message. Moreover, there is no need to send Connection Requests for establishment of communication sessions, because now Controllers can send messages directly to required Agent at any time.</p>
            <p>TR-369 is a transformation suitable for remote management of any network devices (including Smart Home and IoT devices). The connection of device to ACS in TR-369 is faster and lighter compare to TR-069. At the same time this platform <strong>is compatible with Device:2 TR-069 data model</strong>. This guarantees an easy way of migration for those already utilizing CPE wan management protocol for remote CPE management. The seamless migration to USP without having to re-architect OSS/BSS or device/driver integration is certainly very convenient for smooth operation of already existing system architecture.</p>
            <p>With pervasive introduction of 5G network the number of network compatible devices will increase significantly and there will be a need to manage them all. The already released version of Broadband TR-369 USP 1.0 provides wide possibilities for their monitoring, control and interaction.</p>
          </div>
        </div>
      </Container>
    </article>
  </Layout>
  )
}

export default TheUserServicesPlatformTr369
